import { Bet, BetPosition, Round, PredictionUser } from 'state/types'
import numberOrNull from 'utils/numberOrNull'
import { RoundResponseBNB } from './bnbQueries'

const getRoundPosition = (positionResponse: string) => {
  if (positionResponse === 'Bull') {
    return BetPosition.BULL
  }

  if (positionResponse === 'Bear') {
    return BetPosition.BEAR
  }

  if (positionResponse === 'House') {
    return BetPosition.HOUSE
  }

  return null
}

export const transformBetResponseBNB = (betResponse): Bet => {
  const bet = {
    id: betResponse.id,
    hash: betResponse.hash,
    block: numberOrNull(betResponse.block),
    amount: betResponse.amount ? parseFloat(betResponse.amount) : 0,
    position: betResponse.position === 'Bull' ? BetPosition.BULL : BetPosition.BEAR,
    claimed: betResponse.claimed,
    claimedAt: numberOrNull(betResponse.claimedAt),
    claimedBlock: numberOrNull(betResponse.claimedBlock),
    claimedHash: betResponse.claimedHash,
    claimedBNB: betResponse.claimedBNB ? parseFloat(betResponse.claimedBNB) : 0,
    claimedNetBNB: betResponse.claimedNetBNB ? parseFloat(betResponse.claimedNetBNB) : 0,
    createdAt: numberOrNull(betResponse.createdAt),
    updatedAt: numberOrNull(betResponse.updatedAt),
  } as Bet

  if (betResponse.user) {
    bet.user = transformUserResponseBNB(betResponse.user)
  }

  if (betResponse.round) {
    bet.round = transformRoundResponseBNB(betResponse.round)
  }

  return bet
}

// export const transformUserResponseBNB = (userResponse): PredictionUser => {
//   const {
//     id,
//     createdAt,
//     updatedAt,
//     block,
//     totalBets,
//     totalBetsBull,
//     totalBetsBear,
//     totalBNB,
//     totalBNBBull,
//     totalBNBBear,
//     totalBetsClaimed,
//     totalBNBClaimed,
//     winRate,
//     averageBNB,
//     netBNB,
//   } = userResponse || {}

//   return {
//     id,
//     createdAt: numberOrNull(createdAt),
//     updatedAt: numberOrNull(updatedAt),
//     block: numberOrNull(block),
//     totalBets: numberOrNull(totalBets),
//     totalBetsBull: numberOrNull(totalBetsBull),
//     totalBetsBear: numberOrNull(totalBetsBear),
//     totalBNB: totalBNB ? parseFloat(totalBNB) : 0,
//     totalBNBBull: totalBNBBull ? parseFloat(totalBNBBull) : 0,
//     totalBNBBear: totalBNBBear ? parseFloat(totalBNBBear) : 0,
//     totalBetsClaimed: numberOrNull(totalBetsClaimed),
//     totalBNBClaimed: totalBNBClaimed ? parseFloat(totalBNBClaimed) : 0,
//     winRate: winRate ? parseFloat(winRate) : 0,
//     averageBNB: averageBNB ? parseFloat(averageBNB) : 0,
//     netBNB: netBNB ? parseFloat(netBNB) : 0,
//   }
// }

export const transformUserResponseBNB = (userResponse): PredictionUser => {
  const {
    id,
    createdAt,
    updatedAt,
    block,
    totalBets,
    totalBetsBull,
    totalBetsBear,
    totalETH,
    totalETHBull,
    totalETHBear,
    totalBetsClaimed,
    totalETHClaimed,
    winRate,
    averageETH,
    netETH,
  } = userResponse || {}

  return {
    id,
    createdAt: numberOrNull(createdAt),
    updatedAt: numberOrNull(updatedAt),
    block: numberOrNull(block),
    totalBets: numberOrNull(totalBets),
    totalBetsBull: numberOrNull(totalBetsBull),
    totalBetsBear: numberOrNull(totalBetsBear),
    totalETH: totalETH ? parseFloat(totalETH) : 0,
    totalETHBull: totalETHBull ? parseFloat(totalETHBull) : 0,
    totalETHBear: totalETHBear ? parseFloat(totalETHBear) : 0,
    totalBetsClaimed: numberOrNull(totalBetsClaimed),
    totalETHClaimed: totalETHClaimed ? parseFloat(totalETHClaimed) : 0,
    winRate: winRate ? parseFloat(winRate) : 0,
    averageETH: averageETH ? parseFloat(averageETH) : 0,
    netETH: netETH ? parseFloat(netETH) : 0,
  }
}

export const transformRoundResponseBNB = (roundResponse: RoundResponseBNB): Round => {
  const {
    id,
    epoch,
    failed,
    position,
    startAt,
    startBlock,
    startHash,
    lockAt,
    lockBlock,
    lockHash,
    lockPrice,
    lockRoundId,
    closeAt,
    closeBlock,
    closeHash,
    closePrice,
    closeRoundId,
    totalBets,
    totalAmount,
    bullBets,
    bullAmount,
    bearBets,
    bearAmount,
    bets = [],
  } = roundResponse

  return {
    id,
    failed,
    startHash,
    lockHash,
    lockRoundId,
    closeRoundId,
    closeHash,
    position: getRoundPosition(position),
    epoch: numberOrNull(epoch),
    startAt: numberOrNull(startAt),
    startBlock: numberOrNull(startBlock),
    lockAt: numberOrNull(lockAt),
    lockBlock: numberOrNull(lockBlock),
    lockPrice: lockPrice ? parseFloat(lockPrice) : 0,
    closeAt: numberOrNull(closeAt),
    closeBlock: numberOrNull(closeBlock),
    closePrice: closePrice ? parseFloat(closePrice) : 0,
    totalBets: numberOrNull(totalBets),
    totalAmount: totalAmount ? parseFloat(totalAmount) : 0,
    bullBets: numberOrNull(bullBets),
    bullAmount: bullAmount ? parseFloat(bullAmount) : 0,
    bearBets: numberOrNull(bearBets),
    bearAmount: bearAmount ? parseFloat(bearAmount) : 0,
    bets: bets.map(transformBetResponseBNB),
  }
}
